const ImgCollection = [
  { id: 1, src: "PortfolioImg1" },
  { id: 2, src: "PortfolioImg2" },
  { id: 3, src: "PortfolioImg3" },
  { id: 4, src: "PortfolioImg4" },
  { id: 5, src: "PortfolioImg5" },
  { id: 6, src: "PortfolioImg6" },
  { id: 7, src: "PortfolioImg7" },
  { id: 8, src: "PortfolioImg8" },
  { id: 9, src: "PortfolioImg9" },
  { id: 10, src: "PortfolioImg10" },
  { id: 11, src: "PortfolioImg11" },
  { id: 12, src: "PortfolioImg12" },
  { id: 13, src: "PortfolioImg13" },
  { id: 14, src: "PortfolioImg14" },
  { id: 15, src: "PortfolioImg15" },
  { id: 16, src: "PortfolioImg16" },
  { id: 17, src: "PortfolioImg17" },
  { id: 18, src: "PortfolioImg18" },
  { id: 19, src: "PortfolioImg19" },
  { id: 20, src: "PortfolioImg20" },
  { id: 21, src: "PortfolioImg21" },
  { id: 22, src: "PortfolioImg22" },
  { id: 23, src: "PortfolioImg23" },
  { id: 24, src: "PortfolioImg24" },
  { id: 25, src: "PortfolioImg25" },
  { id: 26, src: "PortfolioImg26" },
];

export default ImgCollection;
